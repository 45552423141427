import React, { useEffect } from 'react';
import { Row, Col, Typography, Select, Divider, Switch, Input } from 'antd';
import { useFormContext, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import WarningAlert from './WarningAlert';
import { useNotificationsState, getActionTypeName } from './State';
import translator from '@webpack/translator';
const t = translator('front_office.views.altegio_notification_settings.');
const { Text } = Typography;

const createVisitTimeOffsetStep = ({ offsetsStateKey, showSelectedRequest = false, helpText = '', alerts = [] } = {}) => ({
  title: t('steps.time'),
  validation: ({ selected_request, day_after_previous_visit_count }) => !selected_request || day_after_previous_visit_count,
  Component: ({ isVisible }) => {
    const { state } = useNotificationsState();
    const { action_type } = useParams();
    const { control, watch, setValue } = useFormContext();
    const isSelectedRequest = watch('selected_request');

    const handleDaysChange = onChange =>
      e => {
        const { value } = e.target;

        const reg = /^\d+$/;
        if (reg.test(value) || value === '') {
          onChange(value);
        }
      };

    useEffect(() => {
      if (!isSelectedRequest) {
        setValue('day_after_previous_visit_count', null);
      }
    }, [isSelectedRequest]);

    return <Row gutter={[0, 24]} style={{ display: isVisible ? 'flex' : 'none' }}>
      {alerts.map((alert, index) =>
        <WarningAlert key={index} message={alert} />
      )}
      <Col sm={24} xl={11}>
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Text strong>{getActionTypeName(action_type)}</Text>
          </Col>
          <Col span={24}>
            <Text>{helpText}</Text>
          </Col>
        </Row>
      </Col>
      <Col sm={0} xl={2} />
      <Col sm={24} xl={11}>
        <Controller
          control={control}
          name='visit_time_offset'
          defaultValue={state[offsetsStateKey][0].value}
          render={({ field: { onChange, value } }) => (
            <Select
              className='ant-custom-select'
              style={{ width: "100%", maxWidth: 320 }}
              popupClassName='ant-custom-select-dropdown'
              options={state[offsetsStateKey]}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </Col>
      {showSelectedRequest &&
        <>
          <Divider className='altegio-notification-settings-create__divider' />
          <Col sm={24} xl={11}>
            <Row gutter={[0, 8]}>
              <Col span={24}>
                <Text strong>{t('fields.selected_request')}</Text>
              </Col>
              <Col span={24}>
                <Text><span dangerouslySetInnerHTML={{ __html: t('fields.selected_request_help') }} /></Text>
              </Col>
            </Row>
          </Col>
          <Col sm={0} xl={2} />
          <Col sm={24} xl={11}>
            <div className='altegio-notification-settings-create__selected-request-switch'>
              <Controller
                control={control}
                name='selected_request'
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    id='selected_request'
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
              <label htmlFor='selected_request'>{t('fields.selected_request_switch')}</label>
            </div>
            <div className='altegio-notification-settings-create__selected-request-days'>
              <label htmlFor='day_after_previous_visit_count'>{t('fields.selected_request_days')}</label>
              <Controller
                defaultValue={null}
                control={control}
                name='day_after_previous_visit_count'
                render={({ field: { onChange, value } }) => (
                  <Input
                    id='day_after_previous_visit_count'
                    style={{ width: "100%", maxWidth: 320 }}
                    className={'ant-custom-input'}
                    value={value}
                    onChange={handleDaysChange(onChange)}
                    placeholder={t('fields.selected_request_days_placeholder')}
                    disabled={!isSelectedRequest}
                  />
                )}
              />
            </div>
          </Col>
        </>
      }
    </Row>
  }
});

export default createVisitTimeOffsetStep;
