import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Row, Cell } from '@components/grid';
import Radio from '@front_office/components/payment/Radio';
import Button from '@components/Button';
import Helper from '@webpack/helper.js';

import sms from '@imgs/front_office/payment/sms.svg';
import cg_balance from '@imgs/front_office/payment/chategy.io/balance.svg';
import jet_balance from '@imgs/front_office/payment/jetapi.io/balance.svg';
import bank_card from '@imgs/front_office/payment/bank_card.svg';
import import_bank_card from '@imgs/front_office/payment/import_bank_card.svg';
import qiwi from '@imgs/front_office/payment/qiwi.svg';
import legal_person from '@imgs/front_office/payment/legal_person.svg';

import translator from '@webpack/translator';
const t = translator('front_office.views.payments.variants.payment_methods.');

const smsImg = (projectScope) => {
  switch (projectScope) {
    case 'chatpush.ru':
      return sms;
    case 'jetapi.io':
      return jet_balance;
    default:
      return cg_balance;
  }
};

const PaymentMethods = ({
  setStep,
  payment_type,
  payment_sum,
  create_url,
  payment_methods,
  currency,
  projectScope,
  period,
  paymentObject,
  paidPeriodTranslation,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [rails_errors, setRailsErrors] = useState([]);

  const onSubmit = (data) => {
    if ($.isEmptyObject(errors)) {
      const form_data = new FormData();
      for (let key in data) {
        form_data.append(key, data[key]);
      }

      if (payment_type != 'manual') {
        form_data.append('paid_period', period);
        form_data.append('payment_object', paymentObject);
        form_data.append('paid_period_translation', paidPeriodTranslation);
      }

      fetch(create_url, {
        method: 'POST',
        headers: {
          Accept: 'text/html',
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        },
        body: form_data,
      })
        .then((response) => response.json())
        .then((res_data) => {
          if (res_data.redirect_to) {
            window.location.href = res_data.redirect_to;
          }

          if (res_data.errors) {
            setRailsErrors(res_data.errors);
          }
        });
    }
  };

  const icons = { sms, bank_card, import_bank_card, qiwi, legal_person };
  const radioButtonsValidate = register('gateway_type', { required: true });

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Cell md={3} lg={3}></Cell>
          <Cell md={6} lg={6}>
            <div className="payment-header">
              <div>
                <h3>{t('payment_replenish')}</h3>
                <h5>
                  {t('payment_sum')}: <b>{Helper.formatMoney(payment_sum, currency)}</b>
                </h5>
                <h5>{t('select_payment_method')}</h5>
              </div>
              <div className="img_box">
                <img src={smsImg(projectScope)} />
              </div>
            </div>
          </Cell>
          <Cell md={3} lg={3}></Cell>
        </Row>
        <Row>
          <Cell md={3} lg={3}></Cell>
          <Cell md={6} lg={6}>
            <div className="payment-body">
              <div className="errors_box">
                {rails_errors &&
                  rails_errors.slice(0).map((error) => <span key={error}>{error}</span>)}
                {errors.gateway_type && <span>{t('type_payment_not_empty')}</span>}
                {errors.payment_sum && <span>{t('payment_sum_not_empty')}</span>}
                {errors.payment_method && <span>{t('payment_method_not_empty')}</span>}
              </div>
              <input type={'hidden'} name={'payment_method'} value="" />
              <input
                type={'hidden'}
                name={'sum'}
                value={payment_sum}
                {...register('sum', { required: true })}
              />
              {payment_methods.map(({ title, value, content, payment_method, img }) => (
                <Radio
                  key={value}
                  title={title}
                  name={'gateway_type'}
                  value={value}
                  content={content}
                  onClick={() => setValue('payment_method', payment_method)}
                  validate={radioButtonsValidate}
                  img={icons[img]}
                />
              ))}
            </div>
          </Cell>
          <Cell md={3} lg={3}></Cell>
        </Row>
        <Row>
          <Cell md={3} lg={3}></Cell>
          <Cell md={6} lg={6}>
            <div className="payment-footer">
              <Button
                className={'btn_v2-secondary'}
                text={t('back_btn')}
                onClick={() => setStep(payment_type)}
              />
              <Button htmlType={'submit'} className={'btn_v2-primary'} text={t('next_btn')} />
            </div>
          </Cell>
          <Cell md={3} lg={3}></Cell>
        </Row>
      </form>
    </Container>
  );
};

export default PaymentMethods;
