export default class Helper {
  static debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args) }, timeout);
    };
  }

  static arrayFromMinMax(min, max) {
    return Array.from({ length: max - min + 1 }, (_, i) => i + min);
  }

  static arrayToString(arr) {
    switch (arr.length) {
      case 0:
        return "";
      case 1:
        return `${arr[0]}`;
      case 2:
        return `${arr[0]}, ${arr[1]}`;
      default:
        return `${arr[0]}-${arr[arr.length - 1]}`;
    }
  }

  static removeHashFromLocation() {
    history.replaceState("", "", location.pathname + location.search);
  }

  static getHashFromLocation(defaultValue = "") {
    return location.hash.slice(1) || defaultValue;
  }

  static setCookie(key, value) {
    var expires = new Date();
    expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000);
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
  }

  static getCookie(key) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${key}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  static getBrowserLanguage(available_languages) {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages.find((element, _index, _array) => {
        return available_languages.includes(element);
      });
    }

    return navigator.language;
  }

  static isValidCurrencyCode(code) {
    return /^[A-Z]{3}$/i.test(code);
  }

  static getUTCOffset(timeZone = 'UTC', date = new Date()) {
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
    return (tzDate.getTime() - utcDate.getTime()) / 6e4;
  }

  static formatUTCOffset(minutes) {
    const sign = minutes < 0 ? '-' : '+';
    const absMinutes = Math.abs(minutes);
    const hours = Math.floor(absMinutes / 60);
    const mins = absMinutes % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMins = String(mins).padStart(2, '0');

    return `${sign}${formattedHours}:${formattedMins}`;
  }

  static formatInterval(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  static formatMoney(amount, currency, language = "ru") {
    if (Helper.isValidCurrencyCode(currency)) {
      return amount.toLocaleString(language, { style: 'currency', currency: currency });
    }
    else {
      return amount.toLocaleString(language);
    }
  }
}
