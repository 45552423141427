import React, { useState, useEffect, useMemo } from 'react';
import Helper from '@webpack/helper.js';
import { Row, Col, Typography, Slider } from 'antd';
import WarningAlert from './WarningAlert';
import TextWithPreview from './TextWithPreview';
import { useFormContext, Controller } from 'react-hook-form';
import { useNotificationsState } from './State';
import translator from '@webpack/translator';
const t = translator('front_office.views.yclients_notification_settings.');
const { Text } = Typography;

const createBotFeedbackTextTextStep = ({ alerts = [], helpText = t('fields.text_help') } = {}) => ({
  title: t('steps.text'),
  validation: ({ body }, { body_errors }) => (body || "").length > 0 && (body_errors || []).length === 0,
  Component: ({ isVisible }) => {
    const { state } = useNotificationsState();
    const { control, setValue, watch, formState: { isLoading: isFormDefaultValuesLoading } } = useFormContext();

    const bot_negative_values = state['bot_responses']['negative_feedback']['values'];
    const bot_positive_values = state['bot_responses']['positive_feedback']['values'];
    const bot_available_negative_values = state['bot_responses']['negative_feedback']['bot_response_available_values'];
    const bot_available_positive_values = state['bot_responses']['positive_feedback']['bot_response_available_values'];
    const minBotAvailableNegativeValue = Math.min(...bot_available_negative_values);
    const maxBotAvailableNegativeValue = Math.max(...bot_available_negative_values);
    const minBotAvailablePositiveValue = Math.min(...bot_available_positive_values);
    const maxBotAvailablePositiveValue = Math.max(...bot_available_positive_values);
    const [feedbackSliderValue, setFeedbackSliderValue] = useState(1);
    const currentNegativeFeedbackValues = watch('negative_feedback_values', bot_negative_values);
    const currentPositiveFeedbackValues = watch('positive_feedback_values', bot_positive_values);
    const [feedbackText, setFeedbackText] = useState('');

    useEffect(() => {
      if (!isFormDefaultValuesLoading) {
        const newSliderValue =
          minBotAvailablePositiveValue +
          maxBotAvailablePositiveValue -
          Math.min(...currentPositiveFeedbackValues);
        setFeedbackSliderValue(newSliderValue);
      }
    }, [isFormDefaultValuesLoading]);

    const handleSliderChange = value => {
      const negativeValues = Helper.arrayFromMinMax(
        minBotAvailableNegativeValue,
        maxBotAvailableNegativeValue - value + minBotAvailableNegativeValue + 1
      ).map(String);
      const positiveValues = Helper.arrayFromMinMax(
        maxBotAvailablePositiveValue - value + minBotAvailablePositiveValue,
        maxBotAvailablePositiveValue
      ).map(String);

      setValue('negative_feedback_values', negativeValues);
      setValue('positive_feedback_values', positiveValues);
      setFeedbackSliderValue(value);
    };

    useEffect(() => {
      const negativeValues = Helper.arrayToString(currentNegativeFeedbackValues);
      const positiveValues = Helper.arrayToString(currentPositiveFeedbackValues);

      setFeedbackText(t('fields.negative_and_positive_values', { neg: negativeValues, pos: positiveValues }));
    }, [currentNegativeFeedbackValues, currentPositiveFeedbackValues]);

    // holds something like this for reversed slider: { 2: "5", 3: "4", 4: "3", 5: "2" }
    const sliderMarks = useMemo(
      () => Object.assign(
        {},
        ...bot_available_positive_values.map((item, idx) => (
          { [item]: String(bot_available_positive_values[bot_available_positive_values.length - 1 - idx]) }
        ))
      ),
      [bot_available_positive_values]
    );

    return <Row gutter={[0, 24]} style={{ display: isVisible ? 'flex' : 'none' }}>
      {alerts.map((alert, index) =>
        <WarningAlert key={index} message={alert} />
      )}
      <Col span={24}>
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Text strong>{t('fields.bot_feedback_request_slider')}</Text>
          </Col>
          <Col span={24}>
            <Text>{t('fields.bot_feedback_request_slider_help')}</Text>
          </Col>
          <Col sm={24} xl={11}>
            <Controller
              control={control}
              name='negative_feedback_values'
              defaultValue={state['bot_responses'][`negative_feedback`]['values']}
              render={({ field: { name, value } }) => (
                <input type="hidden" name={name} value={value} />
              )}
            />
            <Controller
              control={control}
              name='positive_feedback_values'
              defaultValue={state['bot_responses'][`positive_feedback`]['values']}
              render={({ field: { name, value } }) => (
                <input type="hidden" name={name} value={value} />
              )}
            />
            <Slider
              className='ant-custom-slider'
              value={feedbackSliderValue}
              onChange={handleSliderChange}
              reverse
              dots
              min={minBotAvailablePositiveValue}
              max={maxBotAvailablePositiveValue}
              tooltip={{ open: false }}
              marks={sliderMarks}
            />
          </Col>
          <Col sm={0} xl={13} />
          <Col sm={24} xl={11}>
            <Text type='secondary'>
              {feedbackText}
            </Text>
          </Col>
        </Row>
      </Col>
      <TextWithPreview fieldName='body' isWithAttachment isVisible={isVisible} helpText={helpText} />
    </Row>
  }
});

export default createBotFeedbackTextTextStep;
