import React, { useEffect } from 'react';
import { Row, Col, Collapse, Input, Typography, Checkbox } from 'antd';
import { useNotificationsState } from './State';
import { useFormContext, Controller } from 'react-hook-form';
import WarningAlert from './WarningAlert';
import TextWithPreview from './TextWithPreview';
import translator from '@webpack/translator';
const t = translator('front_office.views.altegio_notification_settings.');
const { Panel } = Collapse;
const { Text } = Typography;

const createVisitConfirmationTextStep = ({ alerts = [], helpText = t('fields.text_help') } = {}) => ({
  title: t('steps.text'),
  validation: (
    { body, confirm_visit_feedback_body, cancel_visit_feedback_body },
    { body_errors, confirm_visit_feedback_body_errors, cancel_visit_feedback_body_errors }
  ) =>
    (body || "").length > 0 &&
    (confirm_visit_feedback_body || "").length > 0 &&
    (cancel_visit_feedback_body || "").length > 0 &&
    (body_errors || []).length === 0 &&
    (confirm_visit_feedback_body_errors || []).length === 0 &&
    (cancel_visit_feedback_body_errors || []).length === 0,
  Component: ({ isVisible }) => {
    const { state } = useNotificationsState();
    const { control, watch, setValue, formState: { isLoading: isFormDefaultValuesLoading } } = useFormContext();
    const confirm_visit_feedback_values = state['bot_responses']['confirm_visit_feedback']['values'];
    const cancel_visit_feedback_values = state['bot_responses']['cancel_visit_feedback']['values'];

    const [confirmFeedbackDisabled, setConfirmFeedbackDisabled] = React.useState(false);
    const [cancelFeedbackDisabled, setCancelFeedbackDisabled] = React.useState(false);

    const [confirmFeedbackValuesToDisplay, setConfirmFeedbackValuesToDisplay] = React.useState(null);
    const [cancelFeedbackValuesToDisplay, setCancelFeedbackValuesToDisplay] = React.useState(null);

    const currentConfirmFeedbackValues = watch('confirm_visit_feedback_values', confirm_visit_feedback_values);
    const currentCancelFeedbackValues = watch('cancel_visit_feedback_values', cancel_visit_feedback_values);

    useEffect(() => {
      if (!isFormDefaultValuesLoading) {
        const isEmptyConfirmFeedbackValues = currentConfirmFeedbackValues.length === 0;
        const isEmptyCancelFeedbackValues = currentCancelFeedbackValues.length === 0;

        setConfirmFeedbackDisabled(isEmptyConfirmFeedbackValues);
        setCancelFeedbackDisabled(isEmptyCancelFeedbackValues);
        setConfirmFeedbackValuesToDisplay(isEmptyConfirmFeedbackValues ? confirm_visit_feedback_values : currentConfirmFeedbackValues);
        setCancelFeedbackValuesToDisplay(isEmptyCancelFeedbackValues ? cancel_visit_feedback_values : currentCancelFeedbackValues);
      }
    }, [isFormDefaultValuesLoading]);

    const handleConfirmFeedbackDisabledChange = (e) => {
      const disabled = e.target.checked;

      setValue('confirm_visit_feedback_values', disabled ? [] : confirmFeedbackValuesToDisplay);
      setConfirmFeedbackDisabled(disabled);
    };

    const handleCancelFeedbackDisabledChange = (e) => {
      const disabled = e.target.checked;

      setValue('cancel_visit_feedback_values', disabled ? [] : cancelFeedbackValuesToDisplay);
      setCancelFeedbackDisabled(disabled);
    };

    return <Row gutter={[0, 24]} style={{ display: isVisible ? 'flex' : 'none' }}>
      {alerts.map((alert, index) =>
        <WarningAlert key={index} message={alert} />
      )}
      <TextWithPreview
        fieldName='body'
        isWithAttachment
        isVisible={isVisible}
        helpText={helpText}
      />
      <Col span={24}>
        <Collapse className='ant-custom-collapse'>
          <Panel header={t('fields.confirm_visit_feedback')} key="1">
            <Row gutter={[0, 24]}>
              <Col sm={24} xl={11}>
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <Checkbox
                      checked={confirmFeedbackDisabled}
                      onChange={handleConfirmFeedbackDisabledChange}
                    >
                      {t('fields.disable_this_feedback')}
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Text disabled={confirmFeedbackDisabled}>
                      {t('fields.confirm_visit_feedback_values')}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Input
                      disabled
                      className='ant-custom-input'
                      name='confirm_visit_feedback_values'
                      value={confirmFeedbackValuesToDisplay}
                    />
                    <Controller
                      control={control}
                      name='confirm_visit_feedback_values'
                      defaultValue={confirm_visit_feedback_values}
                      render={({ field: { name, value } }) => (
                        <input type='hidden' name={name} value={value} />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={0} xl={13} />
              <TextWithPreview
                disabled={confirmFeedbackDisabled}
                fieldName='confirm_visit_feedback_body'
                defaultValue={state['bot_responses']['confirm_visit_feedback']['text']}
                isWithAttachment={false}
                isVisible={isVisible}
                title={t('fields.bot_response')}
                helpText={t('fields.text_confirm_visit_help')}
              />
            </Row>
          </Panel>
          <Panel header={t('fields.cancel_visit_feedback')} key="2">
            <Row gutter={[0, 24]}>
              <Col sm={24} xl={11}>
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <Checkbox
                      checked={cancelFeedbackDisabled}
                      onChange={handleCancelFeedbackDisabledChange}
                    >
                      {t('fields.disable_this_feedback')}
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Text disabled={cancelFeedbackDisabled}>
                      {t('fields.cancel_visit_feedback_values')}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Input
                      disabled
                      className='ant-custom-input'
                      name='cancel_visit_feedback_values'
                      value={cancelFeedbackValuesToDisplay}
                    />
                    <Controller
                      control={control}
                      name='cancel_visit_feedback_values'
                      defaultValue={cancel_visit_feedback_values}
                      render={({ field: { name, value } }) => (
                        <input type='hidden' name={name} value={value} />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={0} xl={13} />
              <TextWithPreview
                disabled={cancelFeedbackDisabled}
                fieldName='cancel_visit_feedback_body'
                defaultValue={state['bot_responses']['cancel_visit_feedback']['text']}
                isWithAttachment={false}
                isVisible={isVisible}
                title={t('fields.bot_response')}
                helpText={t('fields.text_cancel_visit_help')}
              />
            </Row>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  }
});

export default createVisitConfirmationTextStep;
