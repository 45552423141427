import React from 'react';
import Radio from '@front_office/components/payment/Radio';
import Helper from '@webpack/helper.js';

import translator from '@webpack/translator';
const t = translator('front_office.views.payments.variants.whatsapp.');

const PaymentRadios = ({ list, setValue, currency }) =>
  list.map((opt) => (
    <Radio
      key={opt.sum}
      title={opt.translation}
      label={
        opt.discount_percent > 0 &&
        t('payment_radio_discount', { discount_percent: opt.discount_percent })
      }
      name={'payment_radio_sum'}
      value={opt.sum}
      onClick={() => {
        setValue('payment_sum', opt.sum);
        setValue('period', opt.period);
        setValue('translation', opt.translation);
      }}
      content={
        <div>
          {t('payment_radio_label')}{' '}
          {opt.discount_percent > 0 && (
            <span className="full_price">
              {Helper.formatMoney(opt.sum_without_discount, currency)}
            </span>
          )}{' '}
          <b className="actual_price">{Helper.formatMoney(opt.sum, currency)}</b>
        </div>
      }
    />
  ));

export default PaymentRadios;
