import React, { useState, useEffect, useRef, useCallback } from 'react';
import Helper from '@webpack/helper.js';
import axios from 'axios';
import EmojiPicker from '../../components/EmojiPicker';
import { PlusOutlined, SmileOutlined } from '@ant-design/icons';
import { Input, Row, Col, Typography, Dropdown, Popover, Button } from 'antd';
import TextPreview from './TextPreview';
import AttachmentUpload from './AttachmentUpload';
import { useFormContext, Controller } from 'react-hook-form';
import { useNotificationsState } from './State';
import translator from '@webpack/translator';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
const t = translator('front_office.views.yclients_notification_settings.');
const { Text } = Typography;
const { TextArea } = Input;

const TextWithPreview = ({ isVisible, disabled = false, fieldName, defaultValue, isWithAttachment = false, title = t('fields.text'), helpText = '' }) => {
  const { state, updateState } = useNotificationsState();
  const { control, setValue, getValues, watch } = useFormContext();
  const currentText = watch(fieldName);
  const [lastInsertPosition, setLastInsertPosition] = useState(-1);
  const [preview, setPreview] = useState("");
  const [runningPreviewRequestsCount, setRunningPreviewRequestsCount] = useState(0);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());
  const textInputRef = useRef(null);
  const nativeTextArea = textInputRef.current?.resizableTextArea.textArea;

  const insertText = (text) => {
    const inputValue = nativeTextArea.value;
    const start = nativeTextArea.selectionStart;
    const end = nativeTextArea.selectionEnd === start
      ? start
      : nativeTextArea.selectionEnd;

    const before = inputValue.substring(0, start);
    const after = inputValue.substring(end);

    const newInputValueBeginning = before + text;
    const newInputValue = newInputValueBeginning + after;

    setValue(fieldName, newInputValue);

    setLastInsertPosition(newInputValueBeginning.length);
  };

  useEffect(() => {
    if (lastInsertPosition >= 0) {
      nativeTextArea.setSelectionRange(lastInsertPosition, lastInsertPosition);
      setLastInsertPosition(-1);
    }
  }, [lastInsertPosition]);

  const { action_type } = useParams();

  const fetchPreview = useCallback(Helper.debounce((formData, abortController) => {
    abortController.abort();
    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    setRunningPreviewRequestsCount(x => x + 1);
    axios({
      signal: newAbortController.signal,
      method: 'POST',
      data: { yclients_notification_setting: formData },
      url: state.preview_url,
      headers: {
        Accept: 'application/javascript',
        'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content,
      }
    }).then(({ data }) => {
      if (data.success) {
        setPreview(data.preview.text);
        updateState({ [`${fieldName}_errors`]: data.preview.text_errors });
      } else {
        setPreview(formData.body);
        updateState({ [`${fieldName}_errors`]: data.preview.text_errors });
      }
    }).catch((error) => {
      if (!axios.isCancel(error)) {
        setPreview(formData.body);
      }
    }).finally(() => {
      setRunningPreviewRequestsCount(x => x - 1);
    });
  }, 500), []);

  useEffect(() => {
    setIsPreviewLoading(runningPreviewRequestsCount !== 0);
  }, [runningPreviewRequestsCount]);

  useEffect(() => {
    if (isVisible) {
      const formData = getValues();
      const {
        action_type,
        salon_id,
        salon_sale_service_id_and_title,
        salon_service_id_and_title
      } = formData;

      const filteredFormData = {
        action_type,
        body: formData[fieldName],
        salon_id,
        salon_sale_service_id_and_title,
        salon_service_id_and_title
      };

      fetchPreview(filteredFormData, abortController);
    } else {
      setPreview("");
      updateState({ [`${fieldName}_errors`]: [] });
    }
  }, [isVisible, currentText]);

  const bodyVariableOptions = {
    items: state.body_variables[action_type],
    onClick: ({ key }) => insertText(key),
  };

  const selectedSalonName = state.salons.find(s => s.value === state.selectedSalon)?.label ||
    t('fields.text_preview_default_salon');

  const isErrorPresent = (state[`${fieldName}_errors`] || []).length !== 0;

  return (
    <>
      <Col sm={24} xl={11}>
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Text disabled={disabled} strong>{title}</Text>
          </Col>
          {helpText &&
            <Col span={24}>
              <Text disabled={disabled}>{helpText}</Text>
            </Col>}
          <Col span={24}>
            <div className={classNames(
              'yclients-notification-settings-create__text-input',
              { 'yclients-notification-settings-create__text-input_error': isErrorPresent }
            )}>
              <Controller
                control={control}
                name={fieldName}
                defaultValue={defaultValue || state.body_examples[action_type] || ''}
                render={({ field: { onChange, value } }) => (
                  <TextArea
                    disabled={disabled}
                    autoSize
                    bordered={false}
                    ref={textInputRef}
                    value={value}
                    onChange={onChange}
                    style={{ padding: 0, flexGrow: 1 }}
                  />
                )}
              />
              {!disabled &&
                <div className='yclients-notification-settings-create__text-input-actions'>
                  <Dropdown trigger='click' menu={bodyVariableOptions} overlayClassName='ant-custom-dropdown'>
                    <Button className='ant-custom-btn'>
                      {t('fields.text_variables')}
                      <PlusOutlined />
                    </Button>
                  </Dropdown>
                  <Popover
                    placement='right'
                    popupClassName={'yclients-notification-settings-create'}
                    trigger="click"
                    content={<EmojiPicker stylesStr='section.picker{border: none;}' onClick={e => insertText(e.detail.unicode)} />}
                  >
                    <Button className='ant-custom-btn' htmlType='button'>
                      <SmileOutlined />
                    </Button>
                  </Popover>
                </div>}
            </div>
            {isErrorPresent && !disabled &&
              <div className='yclients-notification-settings-create__text-input-error-message'>
                {state[`${fieldName}_errors`].join(', ')}
              </div>}
          </Col>
          {isWithAttachment && !disabled &&
            <Col span={24}>
              <AttachmentUpload />
            </Col>}
        </Row>
      </Col>
      <Col sm={0} xl={2} />
      <Col sm={24} xl={11}>
        <TextPreview
          disabled={disabled}
          isLoading={isPreviewLoading}
          text={preview}
          attachmentUrl={isWithAttachment ? state.currentFileUrl : null}
          selectedSalonName={selectedSalonName}
          skeletonHeight={(currentText || "").split(/\r\n|\r|\n/).length} />
      </Col>
    </>
  );
};

export default TextWithPreview;
